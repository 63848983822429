.ant-modal-confirm-body{
  padding: 24px !important;
}
.ant-modal-confirm-btns{
  margin: 0px 24px 24px 0px;

}
.ant-modal-confirm-btns >.ant-btn
{
  border-color: #999 !important;
  border-radius: 3px;
  box-shadow:none !important;
  color: #000000 !important;

}
.ant-modal-confirm-btns > .ant-btn:active
{
  color: #000000 !important;
  box-shadow: none !important;
}
.ant-modal-confirm-btns >.ant-btn-dangerous{
  background: rgb(224, 41, 9) !important;
  color: white !important;
  border-color: rgb(224, 41, 9) !important;
}
.ant-btn-dangerous:hover
{
  background: #ac0303 !important;
  color:  #ffffff !important;
  border: 1px solid #ac0303 !important;
}
