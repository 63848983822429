

.place-img {
  height: 250px;
  max-height: 250px;
  width: 100%;
  padding: 0;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
  cursor: pointer;
}
.left-plc-footer-cont .css-6qjm3j {
  padding: 4px;
}
.place-img-broken{
  height: 250px;
  max-height: 250px;
  object-fit: cover;
  cursor: pointer;
}
.card-container {
  padding: 16px 16px 16px 16px;
}
.card-heading {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  padding-bottom: 16px;
}
.heading-container {
  border-bottom: 1px solid #eeeeee;
}
.main-container-sh {
  width: 305px;
  border-radius: 3px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cccccc;
}
.main-container-sp {
  width: 305px;
  border-radius: 3px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cccccc;
}
.footer-plc-container .place-activity-icon {
  background: rgb(55 180 78 / 0.2);
  margin-right: 8px;
  margin-bottom: 8px;
}
.footer-plc-container .place-activity-icon img {
  border-radius: 0;
  padding: 6px;
  object-fit: contain;
}
.inner-sp {
  margin-top: 16px;
  height: 152px;
  background-color: #f2f2f2;
  margin-bottom: 16px;
}
.desc-title {
  margin-top: 16px;
}
.icon-stack {
  margin-bottom: 15px;
}
.mar-tp {
  margin-top: 16px;
}
.main-container-trvl {
  width: 755px;
  border-radius: 3px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cccccc;
}
.card-heading-trvl {
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: normal;
  text-align: left;
}
.desc-trvl {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #757575;
}
.trvl-filters {
  margin-top: 23px;
}
.filter-txt {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  margin-top: 7px;
  margin-right: 15px !important;
}
.menu-btn-trvl {
  border-radius: 3px !important;
  border: solid 1px #f0f0f0;
  background-color: #ffffff !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  margin-right: 10px;
}
.centered {
  width: 90%;
  margin: auto;
  text-align: center;
}
/* .css-1e0m4s3{
  margin-top: -50px !important;
} */
/* .avatar-container > .css-1e0m4s3 {
  margin-top: unset !important;
} */
.fullname-txt {
  padding-top: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
}
/* .css-am8j60{
  padding: 16px !important;
  display: flow-root;
  ;
} */
.fullname-txt-desc {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
}
.txt-desc {
  margin-top: 16px;
  text-align: center;
  color: #333333;
}
.posted-txt {
  text-align: center;
  padding: 16px;
  font-size: 12px;
}
.centered-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -20px;
}
#main-place-body-count {
  padding: 0px 16px 16px 16px;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
}
.location-lnk {
  color: #37b44e;
}
.location-lnk:hover {
  color: #208320 !important;
  text-decoration: underline;
}

/* .carosel-container> .carousel>.thumbs-wrapper {
  display: none !important;
} */

.ask-question {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 16px;
}
.tab-container{
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  margin: 10px 0px;
}

.inner-container-tab{
  padding: 16px;
  overflow-x: auto;
}
.tab-menu{
  margin-top: 20px;
  color: #37454d;
  font-size: 16px;
  padding-right: 20px;
}
#main-footer {
  padding: 10px 16px 10px 16px;
  border-top: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
}

.Modal-container {
  width: 70%;
  margin: 0px auto;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  display: flow-root;
}
.modal-header {
  height: 48px;
  background-color: #f0f0f0;
}
.header-txt {
  float: left;
  width: 50%;
  padding: 10px 0px 10px 40px;
}
.header-close-btn {
  float: left;
  width: 45%;
  padding: 10px 0px 10px 40px;
}
.modal-body {
  padding-top: 30px;
  width: 90%;
  margin: 0px auto;
}
.modal-footer {
  padding-top: 30px;
  width: 90%;
  margin: 0px auto;
  display: flow-root;
}
.modal-btn {
  float: right;
  margin-bottom: 30px;
}
.header-heading {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #000000;
}
.cls-btn {
  float: right;
  margin-right: 199px;
}
.plc-name {
  margin-bottom: 20px;
}
.sec-footer {
  padding-top: 30px;
  width: 100%;
  margin: 0px auto;
  display: flow-root;
  border-top: 1px solid #eeeeee;
  padding-bottom: 30px;
}
.footer-heading {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #37b44e;
}
.footer-icon {
  height: 14px;
  width: 14px;
  color: #37b44e;
}
.user-avatar{
  text-align: center;
  padding-bottom: 10px;
  cursor: pointer;
}
.user-name-container ,.user-role-container{
  margin-bottom: 10px;
}
.verification-inr-container{
  padding:16px;
  background: #fff !important;
}
.verification-container{
  margin-top: 10px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
  margin-bottom: 10px;
}
.user-name-txt{
  font-size: 26px;
  font-weight: 500;
  color: #37454d;
  text-align: center;
}
.user-address-txt{
  font-size: 16px;
  color: #37454d;
  text-align: center;
}
.badge-container-user{
  margin-top: 15px;
}
.user-address-container{
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}
.lang{
     font-size: 12px !important;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.88;
     letter-spacing: normal;
     text-align: left;
     color: #000000 !important;

}
.user-role-txt{
  font-size: 18px;
  font-weight: 500;
  color: #37454d;
  text-align: center;
}
.User-main-container {
  border-radius: 3px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  margin: 0px auto;
  display: flow-root;
}
.usr-inr-container {
  padding:30px 16px 16px 16px;
    display: flow-root;
    background: #fff !important;
}
.avatar-container-user-card {
  float: left;
  width: 35%;
}
.info-container {
  float: left;
  width: 65%;
}
.usr-name {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
.dot-badge {
  padding-bottom:15px;
  font-size: 14px;
  color: #37454d;
}

.dot-badge > .ant-badge-status-default {
  width: 16px !important;
  height: 16px !important;
}
.dot-badge > .ant-badge-status-text {
  font-size: 12px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left;
  color: #000000 !important;
}
.usr-btm-txt {
  margin: 0px 14px 10px 20px;
}
.dot-badge-inner > .ant-badge-status-text {
  color: #000000 !important;
  font-size: 12px !important;
}
.dot-badge-inner > .ant-badge-status-default {
  width: 16px !important;
  height: 16px !important;
}
.info-footer {
  border: 1px solid #eeeeee;
  display: flow-root;
}
.footer-info-left {
  float: left;
  width: 50%;
}
.footer-info-right {
  float: left;
  width: 50%;
}
.default-btn-footer {
  border: unset !important;
  width: 146px;
  border-right: 1px solid #eeeeee !important;
  background-color: #ffffff !important;
  border-radius: unset !important;
}
.default-btn-footer:focus {
  box-shadow: none !important;
}
.default-btn-footer:hover {
  color: #fff !important;
  background-color: #37b44e !important;
  border-color: #37b44e !important;
}
.default-btn-footer:focus {
  color: #fff !important;
  background-color: #37b44e !important;
  border-color: #37b44e !important;
}
.btn-badge > .ant-badge-status-default {
  width: 16px !important;
  height: 16px !important;
}
.btn-badge > .ant-badge-status-text {
  font-size: 14px;
  color: #000000;
  font-weight: normal !important;
}
/*--------------------Album Container------------------------------*/
.carousel-status {
  top: initial !important;
  right: initial !important;
  bottom: 0 !important;
  margin-bottom: 31px;
  margin-left: 11px;
  text-shadow: unset !important;
  font-size: 12px !important;
  z-index: 9;
}
#footer-main {
  width: 100%;
  padding: 10px 16px 10px 16px;
  border-top: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
}
.Album-container {
  width: 42%;
  margin: 0px auto;
}
.album-body-avatar {
  float: left;
  margin-bottom: 15px;
}
.album-desc-container {
  float: left;
}
.album-desc-txt-caontainer {
  margin-top: 8px;
  margin-left: 8px;
}
.album-desc-txt {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
}
.album-badge {
  margin-left: 8px;
}
.carousel .slide .legend {
  display: none;
}
.carousel .slide img {
  height: 353px;
}
.lefted {
  position: absolute;
  bottom: 25px;
  left: 19%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: white;
}

/* .lefted-arrow {
  display: none;
} */
/* .carousel.carousel-slider .control-arrow {
  background: transparent !important;
  color: #fff !important;
  font-size: 26px !important;
  width: 0 !important;
  height: 0 !important;
  padding: 22px !important;
  top: 42% !important;
  right: 5% !important;
  border-radius: 100% !important;
} */
.tourist-txt {
  font-size: 12px;
  color: #383838;
}

/* .carousel.carousel-slider .control-arrow:hover {
  background: rgba(253, 253, 253, 0.25) !important;
  color: #fff;
  font-size: 26px !important;
  width: 0 !important;
  height: 0 !important;
  padding: 22px !important;
  top: 134px !important;
  right: 5% !important;
  border-radius: 100% !important;
} */
/* .carousel .control-prev.control-arrow {
  left: 20px !important;
} */
/* .carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  transition: unset !important;
} */
/* .righted-arrow {
  display: none;
} */
.lefted > .ant-badge-status > .ant-badge-status-text {
  color: white !important;
  font-size: 12px;
  margin-top: 5px;
}
.lefted > .ant-badge-status > .ant-badge-status-default {
  background: white !important;
  font-size: 10px;
  margin-top: 1px;
}
.carousel .slider {
  min-height: 200px;
}
/* .carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: initial !important;
  border-top: none !important;
  border-bottom: none !important;
  content: "";
} */
/* .left-arrow-img {
  margin: 13px 0px 0px 11px;
} */
/* .right-arrow-img {
  margin: 13px 0px 0px 16px;
} */
.album-txt {
  font-size: 18px;
  font-weight: 500;
}
/*--------------------------------------------------*/
/*----------------place card------------------*/
.top-right-icon {
  position: absolute;
  top: 6%;
  right: 1%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.bottom-right-icon-left {
  position: absolute;
  top: 220px;
  right: 8px;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.bottom-right-icon-right {
  position: absolute;
  top: 220px;
    right: 48px;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.story-txt-container {
  display: flow-root;
  margin: 16px 16px 10px 16px;
}
.place-txt {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
}
.category-txt {
  color: #37b44e;
}
.small-txt-ftr-plc {
  font-size: 12px;
  color: #383838;
}
.footer-plc-container {
  padding: 0px 16px 16px 16px;
  display: flow-root;
}
.left-plc-footer-cont {
  float: left;
  width: 55%;
}
.right-plc-footer-cont {
  float: right;
  width: 45%;
}
.see-facility {
  float: right;
}
.footer-ic {
  margin-right: 10px;
}
.see-facility {
  font-size: 14px;
  color: #000000;
  cursor: pointer;
}
.place-container {
  height: auto;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}
.img-container-plc-card {
  max-width: 340px !important;
}
.timespan-txt {
  color: #7d7d7d;
  font-size: 12px;
}
/*----------------place card------------------*/
.css-e4c0ds {
  max-width: initial !important;
}
.Answar-txt {
  color: #383838;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
}

#txt-area {
  min-height: 150px;
  font-size: 14px;
}

.pak-map-place-container{
  cursor: pointer;
  margin: 10px;
}


.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  top:-34px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
