.place-component-left-container-place-data{
  width: 30%;
  float: left;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
}
.place-component-inner-container{
padding:30px;
display: flow-root;

}
.place-component-container .place-component-inner-container{
  padding: 30px;
  display: flex;
  overflow: hidden;
  align-items: stretch;
}
.place-component-carosal-container{
  width: 70%;
  float: left;
  padding-left: 16px;
}

.Place-data-container{
  padding: 16px;
  display: flow-root;
}
.place-card-data-container{
  width: 100%;
  float: left;
}
.place-card-icon-container{
  width: auto;
  float: left;
}
.place-card-place-name-heading{
  color:  #37b44e;
  text-transform: capitalize;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.7;
}
.place-card-place-name-desc{
  color: #383838;
  font-size: 18px;
  font-weight: 400;
}
.place-data-heading-container{
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.place-card-location-heading{
  padding-top: 14px;
  color: #000;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.7;
}
.place-card-address-container{
  float: left;
  width: 85%;
}
.place-card-timings-container{
  width: 100%;
}
.place-card-select{
  width: 100%;
}
.place-card-timings-heading{
  padding-top: 16px;
  color: #000;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.7;
}
.place-card-heading-main-container{
  display: flow-root;
  float: left;
  width: 100%;
  margin-bottom: 16px;
}
.place-card-timings-heading{
  padding-bottom: 10px;
}
.place-card-carosal-images>img
{
  height: 550px !important;
}
.place-component-activity-container-main{
  float: left;
  margin-bottom: 60px;
  width: 100%;
}
.place-card-weather-containe{
  display: flow-root;
}
.place-component-activities-container{
  width: 100%;
  float: left;
}
.place-card-photo-container{
  width: 75%;
  float: left;
}
.place-component-exp-container{
  width: 100%;
  display: flow-root;
}
.place-component-QA-container-main{
  width: 100%;
  display: flow-root;
  margin-bottom: 60px;
}
.place-component-weather-txt{
  font-weight: normal !important;
  text-transform: lowercase !important;
}
.place-component-weather-main-container{
  width: 75%;
  float: left;
}
.place-card-adv-container{
  width: 25%;
  float: left;
  padding-left: 15px;
}
.weather-card-current-weather-container{
  width: 50%;
  float: left;
}
.weather-container-main{
  display: flow-root;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  padding: 24px;
}
.weather-card-weather-icon{
  float: left;
  width: 50%;
}
.place-card-heading-container {
  width: 75%;
  float: left;
  margin-bottom: 16px;
}
.weather-card-weather{
  float: left;
  width: 50%;
}
.weather-card-weather-desc{
  float: left;
  width: 50%;

}
.weather-card-weather-list{
  list-style:  none;
}
.weather-card-weather-list-item{
  width: 65%;
  padding:10px 0 5px 5px;
  display: flow-root;
  border-bottom: 1px solid #eee;
}
.weather-card-weather .temp-heading {
  color: #37b44e;
  text-transform: capitalize;
  font-size: 42px;
  font-weight: 500;
  line-height: 1.7;
}
.place-card-weather-container {
  display: flow-root;
  margin-bottom: 60px !important;
}
.weather-card-list-data-container{
float: left;
width: 70%;
color: #000;
font-weight: 500;
}
.weather-card-list-value-container{
  width: 30%;
  float: left;
  text-align: center;
}
.weather-icon{
  font-size: 72px;
}

.five-day-weather-list-item .day {
  font-weight: 500;
  min-width: 100px;
  text-align: center;
}
.five-day-weather-list-item .icon {
  width: 75%;
  margin: auto;
}
.five-day-weather-list-item .icon img {
  width: 100%;
  margin: 0 !important;
}
.five-day-weather-list-item .temp {
  color: #37b44e;
  text-align: center;
}
.weather-card-inner-container{
  display: flow-root;
}
.five-day-weather-list{
  list-style: none;
  display:flex;
}
.five-day-weather-list-item{
min-width: 100px;
text-align: center !important;
}
.five-day-weather-container{
  overflow-x: auto;
  max-width: 100%;
}
.direction-container-main{
  float: left;
  width: 100%;
}

.activity-inner-container{
  text-align: center;
  margin-bottom: 16px;
  float: left;
  margin-right: 16px;
  padding: 8px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  background: #fff;
}
.activities-container{
  width: 100%;
  display: flow-root;
}
/* .place-card-main-activity-container{
  width: 70%;
  float: left;
} */
.activities-activity-img{
  width: 100%;
  margin: auto;
}
.activities-title{
  padding-top: 8px;
  color: #000;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.7;
}
.activity-learn-more{
  cursor: pointer;
    text-transform: capitalize;
    color: #37b44e;
}
.place-component-related-story-heading{
  float: left;
  width: 50%;
}
.place-component-related-write-story{
float: left;
width: 50%;
text-align: right;
}
.place-component-see-stories{
  cursor: pointer;
  color: #37b44e;
}

.modal-form{
  padding: 24px;
}
.place-component-uploaded-image-container{
  width: 30%;
  float: left;
}
.place-component-image-desc{
  float: left;
  width: 50%;
  padding-top: 70px;
}
.place-component-uploaded-image{
max-width: 300px;
max-height: 250px;
height: 250px;
}
.post-question-modal >.ant-modal-content>.ant-modal-header{
  background-color: #37b44e;
}

.post-question-modal >.ant-modal-content>.ant-modal-header>.ant-modal-title{
  color: #fff !important;
}
.answer-txt{
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #383838;
  margin-bottom: 16px;
}
.question-card-container-main{
  width: 75%;
  float: left;
}
.question-container {
  width: 638px;
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  padding: 10px;
  margin: 0px auto;
  margin-top: 10px;
  display: flow-root;
}
.place-question-card-container{
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 16px;
  display: flow-root;
  cursor: pointer;
}
.question-txt{
font-size: 18px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.75;
letter-spacing: normal;
color: #000000;
margin-bottom: 16px;
}
.single-question-inner-container{
padding:30px;
display: flow-root;
}
.single-question-left-container{
  width: 75%;
  float: left;
}
.post-answer-main-container{
  padding: 14px;
  background-color: #eeeeee;
  border-radius: 6px;
}
.answer-card-container{
  box-shadow: 0 10px 20px 0px rgba(33,43,54,.05);
  padding: 16px;
}
.answer-card-user-role{
  padding-left: 6px;
}
.related-question-txt{
  color: #37b44e;
  cursor: pointer;
}
.experience-container{
  width: 100%;
    float: left;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 16px;
    border-radius: 10px;
}
.lbl-name >.ant-form-item-label>label::after{
  content: none !important;
}
.traveled-with{
  width: 50%;
}
.experiences-main-container{
  width: 75%;
  float: left;
  border-radius: 10px;
  display: flow-root;

}
.experiences-img-container{
  width: 15%;
    height: 150px;
    float: left;
    padding: 5px;
    margin-right: 8px;
    border: 1px solid rgb(55 180 78 / 0.2);
}
.experiences-img-container:first-child {
  margin-left: 0;
}
.experiences-img-container:nth-child(5) {
  margin-right: 0;
}
.experiences-img{
  width: 100%;
    height: 100%;
}
.experience-container-1 {
  width: 100%;
  display: flow-root;
}
.traveled-inner-container{
  width: 100%;
  float: left;
}
.travelled-list{
  list-style: none;
  display:flex;
  padding: 5px;
}
.travelled-list-item{
  width: 33%;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
.traveled-dynamic-data{
  font-weight: normal !important;
  color: #7d7d7d !important;
}
.place-img-container{
  width: 30%;
  float: left;
}
.place-user-photo-container .place-img{
border-radius: 10px;
box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
}
.user-image-photo-container{
  width: 24%;
  float: left;
  margin: 4px;
  cursor: pointer;
}

.tooltip-marked{
  text-align: center;
}
.place-component-carosal-container .image-gallery .image-gallery-content .image-gallery-slide .image-gallery-image{
  min-height: 662px !important;
  max-height: 662px !important;
  object-fit: cover !important;
}
.place-user-photo-container{
  width: 30%;
  float: left;
  margin-right: 16px;
  margin-bottom: 16px;
}
.question-card-inner-container{
  padding: 16px 0 0 16px;
  display: flow-root;
}
.tag-container .ant-tag {
  color: #37b44e;
  font-size: 14px;
  font-weight: 500;
  background: rgb(50 180 78 / 0.2);
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  margin-bottom: 4px;
}
.experience-container .tag-container {
  margin: 0 8px;
}
.place-component-left-container-place-data .user-data-icon-wrapper {
  padding: 0;
  width: 24px;
  height: 24px;
  background: #fff;
}
.place-component-left-container-place-data .user-data-icon-wrapper .user-data-icons {
  font-size: 16px;
}
.place-card-location-attribute{
  margin: 10px 0;
  display: flow-root;
}
.place-card-reaction-container{
  margin: 10px 0;
  display: flow-root;
}
.ant-modal-body .place-single-img {
  margin: auto;
  width: 100%;
  height: auto;
}
.place-card-reaction-list {
  float: right;
  list-style: none;
}
.place-photo-modal{
  width: 100%!important;
  height: 100vh !important;
  top: 0 !important;

}
.place-card-reaction-list .reaction-item {
  display: inline-block;
  margin-left: 8px;
}
.place-component-carosal-container .image-gallery-thumbnail-inner img.image-gallery-thumbnail-image {
  width: 100%;
    height: 102px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.place-component-carosal-container .image-gallery-thumbnail {
  width: 150px;
  height: 110px;
}
.place-component-carosal-container .image-gallery-thumbnail.active {
  border: none !important;
}
.place-component-carosal-container .image-gallery-thumbnails .image-gallery-thumbnails-container{
  text-align: left !important;
}
.place-component-carosal-container .image-gallery-thumbnail.active img.image-gallery-thumbnail-image {
  box-shadow: 0 0 10px 0 rgb(76 175 80 / 0.5) !important;
}
.place-component-carosal-container .image-gallery-thumbnail:hover {
  border: none !important
}
.place-component-carosal-container .image-gallery-thumbnail.active img.image-gallery-thumbnail-image {
  box-shadow: 0 5px 10px 0 rgb(76 175 80 / 0.5) !important;
}
.place-component-carosal-container .image-gallery-thumbnail {
  margin-right: 16px !important;
}
.place-card-section-heading{
  font-size: 32px;
    text-transform: capitalize;
    color: #000;
    line-height: 1.7;
}
.activities-learn-more-btn{
  color: #37b44e;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 0 !important;
}
.activities-learn-more-btn:hover{
  color: #37b44e;
}
.activities-learn-more-btn:focus{
  color: #37b44e;
}

.place-card-section-desc {
  font-size: 16px;
  color: #383838;
}
.activities-container{
  cursor: pointer;
}
.place-component-related-stories .react-multiple-carousel__arrow--left {
  left: 0px;
}
.place-component-related-stories .react-multi-carousel-item {
  margin-right: 15px;
}
.related-card-story-img {
  min-height: 225px;
  max-height: 225px;
  object-fit: cover;
  width: 100%;
}
.related-carduser-container {
  padding: 15px;
}
.story-card-user-container {
  padding-top: 0;
}
.place-component-related-stories .react-multi-carousel-item * {
  text-align: left;
}
.place-card-story-carousel-container{
  display: flow-root;
  width: 100%;
}

.place-card-story-header {
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
}
.place-component-related-stories {
  margin-bottom: 60px;
}
.user-image-photo {
  height: 200px;
  object-fit: cover;
}
.place-component-related-write-story .user-place-btns {
  margin: 0;
  width: auto;
  padding: 8px 24px;
  height: 100%;
  border-radius: 24px;
}
.place-component-photos-container {
  display: flow-root;
  margin-bottom: 60px;
  width: 100%;
}
.place-question-card-container .small-txt-ftr {
  font-size: 14px;
  color: #383838;
}
.reaction-icon{
  float: left;
    margin-top: 3px;
    margin-right: 4px;
}
.icon-txt{
  float: left;
}
.exp-card-body{
  padding: 16px;
}
.experience-txt {
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #383838;
  margin-bottom: 16px;
}
.place-component-related-write-story .user-place-btns .anticon svg {
  margin-bottom: 0px;
  margin-top: -9px;
}
.no-data-found-content .heading {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7;
}
.no-data-found-content .desc {
  color: #383838;
  font-size: 16px;
  font-weight: 400;
}
.no-data-container {
  text-align: center;
}
.no-data-found-img img {
  width: 200px;
  margin: auto;
}
.all-question-right-container{
width: 25%;
float: left;
padding: 0px 10px;
position: sticky;
top: 16px;
margin-bottom: 125px;
}
 ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, .2);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.activity-model{
  width: 50% !important;
}
.activity-modal-txt {
  padding: 16px 24px !important;
}
.place-component-activities-container .activities-container {
  float: left;
  width: 15%;
}
.no-activity-txt{
  text-align: center;
}
