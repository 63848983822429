.settings-txt-container{
  margin-top: 16px;
}
.settings-heading{
  padding: 24px 0 0 24px;
}
.user-settings-left-menu-list> .ant-menu-item-selected{
  color: #fff !important;
  background-color: #37b44e !important;
}
.user-settings-left-menu-list> .ant-menu-item-selected:hover{
  color: #37b44e !important;
  background-color: #f5f5f5;
}
.user-settings-left-menu-list> .ant-menu-item:hover{
  color: #37b44e !important;
  background-color: #f5f5f5 !important;
}
.user-settings-left-menu-list> .ant-menu-item-selected::after{
  border-right:3px solid   #37b44e !important ;
}
.settings-header-container{
  display: flow-root;
  width: 100%;
  margin-top: 50px;
  padding: 16px;
}
.setting-heading-txt-container{
float: left;
width: 50%;
}
.setting-btn-txt-container{
width: 50%;
float: left;
text-align: right;
}
.setting-heading-txt{
      color: #000;
    text-transform: capitalize;
    font-size: 26px;
    font-weight: 500;
    line-height: 1.7;
    margin-bottom: 10px;
}
.user-setting-content{
  background-color: white !important;
}
.basic-info-container{
margin: 16px;

}
.basic-info-inner-container{
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  padding: 16px;
}
.basic-info-heading{
  color:  #37b44e;
    text-transform: capitalize;
    font-size: 26px;
    font-weight: 500;
    line-height: 1.7;
    margin-bottom: 10px;
}
.setting-drager-container{
background-color: #f6f6f6;
padding:24px
}
.setting-drager-container>.ant-form-item-control{
  max-width: 100% !important;
}
.seting-drager{
  margin: auto;

}
.user-setting-drager-item{
  padding-bottom: 24px;
}
.user-setting-upload-icon >svg
{
  width: 32px;
  height: 32px;
  color: #37b44e;
}
.user-setting-upld-desc{
  color: #37b44e;
}
.seting-drager{
  color: #fff !important;
}
.user-setting-first-name > .ant-form-item-control{

  max-width: 67% !important;
}
.user-setting-about .ant-form-item-control
{
  max-width: 85% !important;
}
.basic-info-second-container{
  margin-top: 16px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  padding: 16px;
}
.user-setting-address-modal>.ant-modal-content>.ant-modal-header{
  background-color: #37b44e;
}
.user-setting-address-modal>.ant-modal-content>.ant-modal-header>.ant-modal-title
{
  color: #fff !important;
}
.user-setting-address-modal>.ant-modal-content>.ant-modal-close>.ant-modal-close-x{
  color: #fff !important;
}
.user-setting-usr-name-attempt{
  margin-bottom: -29px !important;
}
.user-setting-privacy-container{
  width: 100%;
  padding: 16px;
}
.user-setting-list{
  list-style: none;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
}
.user-setting-first-child-div-first{
  width: 50%;
  float: left;
}
.user-setting-first-child-div-second{
  width: 50%;
  float: left;
  text-align: right;
}
.user-setting-list-item{
  padding: 24px;
  display: flow-root;
  border-radius: 2px;
}
.user-setting-list>.user-setting-list-item{
  border-bottom: 1px solid #eeeeee;
}
.user-setting-place-accordian{
  margin-top: 16px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
}
.user-setting-map-accordian{
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
}
.user-settings-header-heading-color>.ant-collapse-header{
color: #37b44e !important;
}
.user-setting-pannel-first-container{
  float: left;
  width: 50%;
}
.user-setting-list-pannel{
list-style: none;
}
.user-setting-list-pannel>.user-setting-list-item-pannel{
  padding: 24px;
    border-bottom: 1px solid #eee;
    display: flow-root;
}
.user-settings-header-heading-color>.ant-collapse-content > .ant-collapse-content-box{
  padding: 0px !important;
}
.user-settings-notification-container{
  margin: 16px;
}
.user-setting-btn
{
  max-width: 300px;
}
.modal-form-item .ant-col-8{
  max-width: 100% !important;
}
.user-settings-change-address-btn{
cursor: pointer;
}
.user-settings-change-address-btn:hover
{
  color: #37b44e;
}
