.support-form-container{
  max-width: 750px;
    width: 100%;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.16);
}
.suppoert-main-container{
  margin-top: 60px;
  margin-bottom: 24px;
}
.support-form{
  padding-left: 43px;
  padding-bottom: 20px;
}
.support-form-heading{
  text-align: center;
  font-size: 24px;
  color: #000;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 1.7;

}
.support-form-header-container{
  padding: 16px;
}
.support-form-desc{
  text-align: center;
  color: #383838;
  font-size: 18px;
  font-weight: 400;
}
.support-form >.ant-form-item{
  margin-bottom: 10px !important;
}
.support-form-avatar{
  text-align: center;
}
.support-form-avatar-container{
  width: 22%;
  margin: auto;
  padding: 16px;
}
.btn-support-form{
  float:right;
}
.terms-privacy-inner-container{
  padding-left: 30px;
    padding-right: 30px;
    display: flow-root;
    padding-top: 60px;
}

.terms-privacy-headings{
    font-size: 42px;
    font-weight: bold;
    color: #000;
    margin-bottom: 26px;
}
.terms-data{
  font-size: 18px;
    font-weight: 400;
    color: #383838;
}
.terms-data p {
  margin: 18px 0;
}
.terms-data ul {
  margin-left: 32px;
  margin-bottom: 18px;

}
.terms-data ul li {
  padding-bottom: 10px;
}
.terms-data table tr td {
  padding: 15px;
  border: 1px solid #000;
}
.terms-data table tr td:first-child {
  width: 15%;
}
.terms-data a {
  color: #37b44e;
}
.terms-data a:hover {
  color: #000000;
  text-decoration: underline;
}
.terms-data table tr td:first-child {
  width: 25%;
}
.terms-data ol {
  margin-left: 32px;
}
.terms-data ul li {
  padding-bottom: 10px;
}
.terms-data ol {
  list-style-type: upper-roman;
}
.terms-data ol li ol {
  list-style-type: lower-alpha;
}
.terms-data ol li::marker {
  font-weight: 700;
}
.terms-data ol {
  margin-left: 32px;
  margin-bottom: 18px;
}
.terms-data ol li {
  padding-bottom: 10px;
}
