.avatar-container-avatar-card{
  margin-bottom: 16px;
  width: 100%;
  text-align: center;
}
.avatar-card-userName{
  text-align: center;
}
.avatar-card-userName-container{
  width: 100%;
  text-align: center;
}
.user-avatar-container-avatar-card{
  cursor: pointer;
}
.avatar-card-user-desc-container{
  text-align: center;
}
.avatar-card-btn-container{
  text-align: center;
  margin-top: 16px;
}

/* ---------------------------------------------Related Card-------------------------------------- */

.related-card-story-img{
  max-height: 500px;
  min-height: 330px;
  width: 100%;
}
.related-carduser-container{
  padding: 10px;
}
.related-card-badge{
  text-align: left !important;
}
.related-card-story-title{
  text-align: left !important;
}
.story-carousel > .react-multi-carousel-track > .react-multi-carousel-item
{
  padding:0 !important
}

/* ---------------------------------------------Related Card-------------------------------------- */
