.story-card-container {
  border-radius: 10px;
  margin-bottom: 30px;
  display: flex;
 padding: 15px;
 overflow: hidden;
  align-items: stretch;
  background: #fff;
  -webkit-box-shadow: 0 2px 3px -2px rgba(0,0,0,.15);
  box-shadow: 0 2px 3px -2px rgba(0,0,0,.15);
  padding: 0;
  cursor: pointer;
}
.stories-main-container{
  background-color: #f7f7f7;
}
.story-card-story-container{
  cursor: pointer;
}
.story-card-user-container{
  display: flow-root;
}
.story-card-desc-container{
  float: left;
}
.story-card-decs-txt-container {
  margin-top: 8px;
  margin-left: 8px;
}
.story-card-desc-txt
 {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
.story-card-tourist-txt {
  font-size: 16px;
  color: #383838;
}
.published-card-data-container{
  cursor: pointer;
}
.story-card-timespan-txt {
  color: #7d7d7d;
  font-size: 14px;
}
.story-card-category-name{
  font-size: 14px !important;
}
.story-card-album-badge {
  margin-left: 8px;
}
.story-card-story-title {
  font-size: 22px;
  font-weight: 500;
  color: #000000;
}

.story-card-place-name{
  color: #37b44e;
  font-size: 16px;
  cursor: pointer;
}
.story-card-story-desc{
  color: #383838;
  font-size: 18px;
  font-weight: 400;

 }
 .story-card-story-desc-container >.story-card-story-desc >p {
   background-color: none !important
 };


 .story-card-story-image{
  background-position: 50% 52%;
  background-repeat: no-repeat ;
  background-size: cover;
  max-height: 100% ;
  border-radius: 0 10px 10px 0 ;
  height: 100% ;

}
.story-card-story-image1{
  background-position: 50% 52%;
  background-repeat: no-repeat ;
  background-size: cover;
  max-height: 100% ;
  border-radius: 0 10px 10px 0 ;
  height: 100% ;
}
.story-card-inner-left-container{
  padding: 15px;
  width: 60%;
  float: left;
}
.story-card-inner-right-container{
  float: left;
  width: 40%;
}
.story-card-story-image-container {
  height: 100%;
  width: 100%;
}

/* ---------------------------------------single story card-------------------------------------------- */
.story-card-user-container
{
  padding-top: 24px;
  cursor: pointer;
}
.single-story-card-container-user
{
  width: 70%;
  float: left;
  display: flow-root;
}
.single-story-card-menu-container{
  float: left;
  width: 30%;
  display: flow-root;
  min-height: 104px !important;
  height: 104px;
  padding: 40px 0px;
}
.single-story-card-story-img-container{
  /* position: sticky; */
  /* top: 0; */
  width: 100%;
  z-index: 1;
}
.single-story-img-banner{
  max-height: 350px;
  width: 100%;
  margin: 16px 0;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.single-story-card-story-title{
  font-size: 22px;
  font-weight: 500;
  color: #000000;
  text-align: center;
}
.single-story-desc{
  text-align: center;
}
.single-story-desc p, .single-story-desc img {
  margin-bottom: 16px;
}
.single-story-desc img{
margin: auto;
margin-bottom: 16px;
}
/* ---------------------------------------single story card-------------------------------------------- */


/* ---------------------------------------loved album card-------------------------------------------- */

.loved-album-banner-container{
  float: left;
  width: 25%;
}
.loved-album-data-container{
  display: flow-root;
  padding-left: 10px;
}
.loved-albums-inner-container{
  margin-bottom: 20px;
  display: flow-root;
  cursor: pointer;
}
.loved-album-image{
  width: 100%;
  min-height: 68px;
  background-position: 50% 52% !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.loved-album-title{
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 5px;
}
.loved-album-count{
  font-size: 14px;
  color: rgb(125, 125, 125);
}
.loved-count{
  color: #d53943;
  padding-right: 10px;
}

/* ---------------------------------------loved album card-------------------------------------------- */
/* ---------------------------------------single album card-------------------------------------------- */
.single-album-image-banner{
width: 33%;
float: left;
padding: 5px;
cursor: pointer;
}
.single-album-image{
  max-height: 175px;
  width: 100%;
}
.carousel-container{
  width: 100%;
  float: left;
  padding: 24px;
}
.single-album-image-carosel >.carousel-slider>.slider-wrapper>.slider>.slide{
  margin-top: 40px;
}
.single-album-card-info-container{
  margin-top: 40px;
}
.single-album-card-info-container{
  width: 25%;
  float: left;
}
.single-album-modal-user-card{
  width: 100%;
  float: left;
}
.single-album-modal-menu{
  width: 20%;
  float: left;
}
/* ---------------------------------------single album card-------------------------------------------- */
.top-story-taller-container {
  background: #fff;
  border: 1px solid #eee;
  -webkit-box-shadow: 0 2px 3px -2px rgba(0,0,0,.15);
  box-shadow: 0 2px 3px -2px rgba(0,0,0,.15);
  padding: 16px;
}
p.top-story-taller-heading{
  border-bottom: 1px solid #eee;
  font-size: 24px;
  text-align: center;
}
.story-like-footer-reaction-container{
  cursor: pointer;
}
