.analytics-users-container{
  width:30%;
  float:left;
  background-color: #eee;
  margin: 16px;
  text-align: center;
}
.analytics-container{
  width: 100%;
  display: flow-root;
}
.albums-container-analytics{

  width:50%;
  background-color: #eee;
  padding: 16px;
  text-align: center;
  margin:auto;
  margin-top: 16px;
  display: flow-root;

}
