.txt-img-container {
  width: 100%;
  position: absolute;
  bottom: 38px;
  left: 223px;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.7)
  );
  height: 131px;
}
.photos-txt {
  margin-left: 75px;
  margin-right: 10px;
  font-size: 12px;
  color: #fff;
}
.control-dots{
  z-index: 1;
  text-align: right;
}
.carousel .control-dots{
  text-align: right !important;

}
.lefted-album-txt {
  position: absolute;
  bottom: 40px;
  left: 159px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: white;
  right: 5px;
}
.album-card-user-container{
  padding: 20px;
  display: flow-root;
}
.carousel-inner-container{
  float: left;
  width: 450px;
   margin: 0 20px 20px 0;
}


.my-album-carousel-images{
  max-height: 250px !important;
}

.small-txt-ftr {
  font-size: 12px;
  font-weight: 500;
  color: #4f4f4f;
}
.small-txt-ftr-albm{
  color: #37b44e !important;
}

.modal-carosel{
  background-color: #fff;
  display: flow-root;
  top:0px !important;
  width: 75% !important;
}
