.tourist-container{
    width: 100%;
    display: inline-block;
}
.left-sec-tour{
    float: left;
    width: 28%;
    border-right: 1px solid #d8d8d8;
}
.inner-container-banner{
  padding:30px;
  display: flex;
  align-items: center;
}
.right-sec-tour{
    float: left;
    width: 70%;
    text-align: center;
}
.banner-avatar{
  margin-left:10px
}
.tourist-heading{

    font-size: 42px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.43;
    letter-spacing: normal;
    text-align: left;
    color: #37454d;
    margin-bottom: 20px;
}
.tour-desc{
    font-size: 18px !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #000000;

}
.join-them{
    margin-top: 30px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    /* text-decoration: underline; */
    cursor: pointer;
}
.join-them-link:hover{
  color: #37b44e !important;
    text-decoration: underline;
}
.tourist-right-arrow{
    font-family: Roboto;
    width: 12px;
    height: 12px;
    position: absolute;
    margin-left: 76px;
    margin-top: -18px;
}
.explore-btn-common{
  margin-top: 30px;
color: #000000;
font-size: 18px;
border: 2px solid #000000;
padding: 5px 20px;
}
.explore-btn-common:hover{
  color: #ffffff;
  background: #000000;
}
.explore-btn-common .anticon {
  margin-left: 10px;
}
