.qa-inner-left{
  float: left;
  width: 30%;
}
.qa-inner-banner{
  background-color: #fbfbfb;
  padding:50px 30px
}
.experience-img{
  max-height: 137px;
}
.qa-container{
  background-color: #fbfbfb;
}
.qa-inner-right{
  float: left;
  width: 70%;
  padding: 0px 0px 0px 10px;
}
.qa-left-sec{
  padding: 40px 30px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
   width: 50%;
  float: left;
  margin-bottom: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.question-img{
  max-height: 137px;
}
.qa-right-sec{
  padding: 40px 30px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
   width: 50%;
  float: left;
  margin-bottom: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.hlp-them-btn{
  margin-top: 0px !important;
}
.qa-heading{
  font-size: 22px;
  font-weight: bold;
  color: #37454d;
  font-family: roboto !important;
}
.qa-desc{
  font-size: 16px;
  color: #37454d;
  margin-bottom: 15px;
}


.lft-margin{
  margin-right: 30px;
}

@media screen and (max-width: 960px) {
 .qa-right-sec{
   width: 100% !important;
   margin-bottom: 30px !important;
    }
 .qa-left-sec{
   width: 100% !important;
   margin-bottom: 30px !important;
 }
 .qa-container .qa-inner-banner{
   display: flow-root;
 }
 .lft-margin
 {
   margin-right: 0px !important;
 }
 .qa-inner-left{
   width: 50%;
 }
  }

