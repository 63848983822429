
.main-container{

    /* text-align: center; */
    margin-top: 65px;
}

.signup-container{
     max-width: 760px;
    width: 100%;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
}

.curr-step{
    margin: 24px 24px 24px 24px !important;
    width: 78% !important;
}
.social-container{

    padding: 167px 0px 0px 16px;
    display: flow-root;
}
.signup-header-container{

    display: inline-block;
    width: 100%;
}
.google-social-btn{
    margin-top: 10px;
    width: 83% !important;
    font-size: 16px !important;
    background-color:  #4285F4 !important;
    color: #ffffff !important;
    box-shadow: none !important;

}
.google-social-btn >span{
    padding: 10px 10px 10px 26px !important;
}
.google-social-btn >div{
    margin-right: 10px !important;
    background: rgb(255, 255, 255) !important;
    padding: 13px 13px 14px 13px !important;
    margin-bottom: 0px !important;
    border-radius: 2px 0px 0px 2px !important;
    margin-left: 0px !important;
    margin-top: -1px !important;
}
.fbBtn{

    width: 316px;
   height: 42px !important;
   border: solid 1px #4f4f4f;
  color: #4f4f4f;
    background-color: white !important;
    border-radius: 2px !important;
    color:black;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13 !important;
    letter-spacing: normal;
    margin-top: 10px;

}
.fa-facebook{
    margin-right: 75px;
    margin-left: 22px;

}
.firstStep{
    margin-left: 24px !important;
    border-right: 1px solid #eeeeee !important;
}
.back-arrow{
    width: 45%;
    float: left;
    cursor: pointer;
    text-align: left;


}
.kep-login-facebook{
    margin-top: 10px;
height: 44px;
padding: initial !important;
text-transform: initial !important;
font-family: roboto !important;
font-size: 16px !important;
font-weight: 500 !important;
width:83% !important;
text-align: left !important;
}
.signup-heading-container{
    width: 55%;
    float: right;
}
.kep-login-facebook:hover{
    background-color: #29487d;
    border-color:#29487d
}
.kep-login-facebook .fa{
margin-right: 50px !important;
}
.register-form{

    width: 50%;
    float: left;
}
.signup-social-container{

    width: 50%;
    float: left;
}
.register-txt{

    padding:24px 0px 24px 0px;
    color:#37b44e;
    font-weight:bold;
    font-size:20px;
    text-align: left;

}
.signup-crte-acc-txt{
    margin-left: 24px;
    font-size: 20px;
    font-weight: bold;
    color: #37454d;
    text-align: left;
}
.signup-crte-acc-desc{
    margin-left: 24px;
    font-size: 16px;
    color: #37454d;
    text-align: left;
    margin-bottom: 20px;
}
.btn-nxt{
    margin-top: 10px;
    width: 319px;
}
.pre-container{
    padding:24px 0px 24px 24px;
    font-size: 20px;
    font-weight: bold;
}
.ant-steps-item-finish .ant-steps-item-icon{
    background-color: #37b44e !important;
    border-color: #37b44e !important;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
    color: #ffffff !important;
}
.ant-steps-item-process .ant-steps-item-icon{
    background-color: #37b44e !important;
    border-color: #37b44e !important;
}
.lbl-name{
    margin-bottom: 0px !important;

}
.ant-form-item-label > label{
    color:rgba(0, 0, 0, 0.65) !important;
    font-weight: 600 !important;
}

.ant-form-item-control-input-content{
    border:none !important;

}
.ant-input-lg{
    border-color:#cccccc !important;
    border-radius: 3px;
}
.ant-input-password{
    border-color:#cccccc !important;
    border-radius: 3px;
}
.ant-input-password:hover{
    border-color: #999 !important;
    border-radius: 3px;
}
.ant-input-lg:hover{
    border-color: #999 !important;
    border-radius: 3px;
}
.ant-input-lg:focus{
    border-color: #999 !important;
    border-radius: 3px;
    box-shadow:none !important
}
.ant-input-password:focus{
    border-color: #999 !important;
    border-radius: 3px;
    box-shadow:none !important
}
.ant-btn-primary{
    background: #37b44e !important;
    border-color:#37b44e !important;
}
.ant-btn-primary:hover{
    background-color: #29a829 !important;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
    background-color:#f0f0f0 !important
}
.conect-social-txt{
    font-size: 14px;
    font-weight: bold;
    color: #37454d;
    text-align: left;

}
.fbBtn:hover{
    color: #000000;
    border-color: #000000;
    background-color: #eeeeee !important;
}
.form-container-sign{
    display: flow-root
}
.trm-txt-sign{
    padding: 5px 0px 30px 24px;

}
.txt-trm{
    font-size: 16px;
    color: #808080;
    text-align: left;
}
.privacy-link{
    color: #37b44e !important;
}
.privacy-link:hover{
  text-decoration: underline;
}
.anticon svg{
    margin-bottom: 6px;
}
.ant-steps-item-title:hover
{
    color: #000000 !important ;

}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon{
    border-color:#000000 !important;

}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon{
    color:#000000 !important ;
}
.ant-steps-item:last-child{
    pointer-events: none !important;
}
.acc-txt{
    margin-top: 24px;
    font-size: 20px;
    font-weight: bold;
    color: #37454d;
    text-align: center;
}
.lgin-btn-container{
    margin-top: 10px;
    margin-bottom: 58px;
    text-align: center;
}
.btn-lgin{
    width: 150px;
   border: solid 1px #4f4f4f !important;
  color: #4f4f4f !important;
    background-color: white !important;
    border-radius: 2px !important;
    color:black;
    font-size: 16px !important;
    font-weight: 500 !important;
}
.btn-lgin:hover{
    color: #000000 !important;
    border-color: #000000 !important;
    background-color: #eeeeee !important;

}
.btn-lgin:focus{
    color: #000000 !important;
    border-color: #000000 !important;
    background-color: #eeeeee !important;
    box-shadow: none !important;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description{
    color: #000000 !important;
}
.logo-image{
    position: absolute;
    top: 31em;
    left: 51em;
    transform: translate(-50%, -50%)
}
.google-image{
    position: absolute;
    top: 31em;
    left: 51em;
    transform: translate(-50%, -50%)
}
.container{
    position: relative;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
font-weight: bold !important;
}
.top-container-signup{
    display: flow-root;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1) !important;
}
.ant-btn-primary[disabled]{
background: #cccccc !important;
color: #949494 !important;
border: #cccccc !important;
}
.left-inner-container{
  padding:30px;
  margin-top: 100px;
}
.signup-success-logo{
  margin-bottom: 20px;
}

.user-desc
{
  font-size: 24px;
  text-transform:capitalize;
  font-weight:500;
  color:#000000;
  margin-bottom: 5px;
}
.green-txt{
  font-size: 24px;
  font-weight: 500;
  color:#37B44E;
  margin-bottom: 30px;
}
.large-txt{

  font-size: 32px;
  font-weight:500;
  color:#000000;
  margin-bottom: 5px;
}
.email-txt{
  color:#000000;
  font-size: 18px;
  margin-bottom: 30px;
}
.sign-success-left-container
{
  width: 50%;
  float: left;
}
.sign-success-right-container
{
  width: 50%;
  height: 100%;
  float: left;
}
.image-div{
  background-image: url("../../Assets/check-mailbox-min.jpg");
  width: auto;
  height: 100vh;
}
.warning-txt{
  margin-top: 30px;
  color:#000000;
  font-size: 18px;
  margin-bottom: 20px;
}
.last-desc-txt{
  color:#000000;
  font-size: 16px;
  font-style: italic;
}
