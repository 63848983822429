.phone-select{
  width: 15% !important;
}
.file-upload{
  border: solid 1px #c1e6bf;
  background-color: #e7fce5;
  padding:2px !important;
  width:305px ;
}

.ant-upload.ant-upload-drag{
  background-color: #e7fce5 !important;
  width:300px ;
  box-shadow: none !important;


}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
  border-color: #999 !important;
  border-radius: 3px;
}

.ant-picker:hover, .ant-picker-focused{
  border-color: #999 !important;
  border-radius: 3px;
  box-shadow: none !important;
}
.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  border-color: #999 !important;
  border-radius: 3px;
  box-shadow: none !important;
}
.ant-select-focused.ant-select-multiple .ant-select-selector{
  box-shadow: none !important;
}


