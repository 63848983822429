.travel-album-container{
  background-image: url('../../Assets/album-bg.jpg');
  object-fit: contain;
  opacity: 1;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.first-img-travel-album{
  background-image: url('../../Assets/river.jpg');
  height:200px;
  width:250px;
  border: 5px solid #ffffff;
  margin: 0px 10px 10px 0px;
  float: left;
  background-size: cover;
  background-position: center;
}
.second-img-travel-album{
  background-image: url('../../Assets/bridge.jpg');
  height:250px;
  width:260px;
  border: 5px solid #ffffff;
  float: left;
  margin-bottom: 10px;
  background-size: cover;
  background-position: center;
}
.third-img-travel-album{
  background-image: url('../../Assets/lake_road.jpg');
  height:250px;
  width:250px;
  border: 5px solid #ffffff;
  float: left;
  margin-top: -50px;
  margin-right: 10px;
  background-size: cover;
  background-position: center;
}
.forth-img-travel-album{
  background-image: url('../../Assets/road.jpg');
  height:200px;
  width:260px;
  border: 5px solid #ffffff;
  float: left;
  margin-bottom: 10px;
  background-size: cover;
  background-position: center;
}
.first-img-travel-album-min{
  width:25%;
  border: 5px solid #ffffff;
  margin: 0px 10px 10px 0px;
  float: left;
}
.second-img-travel-album-min{
  background-image: url('');
  width:25%;
  border: 5px solid #ffffff;
  float: left;
  margin-bottom: 10px;
}
.third-img-travel-album-min{
  background-image: url('');
  width:25%;
  border: 5px solid #ffffff;
  float: left;
  margin-right: 10px;
}
.min-container{
  width: 100%;
  max-width: 1100px;
}
.travel-content{
  text-align: center;
}
.album-btn{
  margin-top: 0px;
}
.inner-content{
  display: flex;
  align-items: center;
}
.min-data{
  display: flow-root;
  width: 100%;
  margin-top: 30px;
}
.forth-img-travel-album-min{
  background-image: url('');
  height:250px;
  width:49%;
  border: 5px solid #ffffff;
  float: left;
  margin-bottom: 10px;
  background-size: cover;
  background-position: center;
}
.travel-album-container:before {
  opacity: 1;
}
.travel-album-images{
  border: 5px solid #ffffff;
}
.travel-album-first-container{
  width: 41%;
  float: left;
}
.travel-album-img-1{
  height:200px;
   width:250px
}
.travel-album-headings{
  margin-bottom: 30px;
  font-size: 42px !important;
}
.travel-album-img-2{
  height:260px;
   width:250px;
   margin-left:2px;
}

.travel-album-img-3{
  height:250px;
   width:250px;
   margin-top: 15px;
}
.travel-album-right{
text-align: center;
}
.travel-album-img-4{
  height:190px;
  width:250px;
   margin-top: 15px;
   margin-left:2px;
}
.travel-album-arrow{
 right: 167px !important;
  margin-top: -27px !important;
}
.travel-album-btn{
  margin-right: 30px !important;
  margin-left: 0px;
}
.album-btn{
  text-align: center;
}
.travel-album-inner{
  background: rgb(255 255 255 / 0.6);
}
.img-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.img-list .img-item {
  float: left;
  width: 25%;
  padding: 10px;
}
.img-list .img-item:first-child{
  padding-left: 0;
}
.img-list .img-item:last-child{
  padding-right: 0;
}
.img-list .img-item img{
 border: 5px solid #ffffff;
 width: 250px;
 height: 200px;
}


@media screen and (max-width: 1100px) {
.third-img-travel-album{
  margin-top: 0px;
  }
  .third-img-travel-album{
    margin-bottom: 10px;
  }
}
