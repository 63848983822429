.container-upper-footer{
  background-image: url('../../Assets/footer_bg_illustration-01.svg');
  object-fit: contain;
  opacity: 1;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.upper-footer-container{
padding: 75px 0px;
}
.inner-footer-container{
  padding-top: 100px;
  display: flow-root;
}
.footer-sec-li-container{
  width: 25%;
  float: left;
  text-align: center;
}
.header-text-footer{
  color: #000000;
  text-transform: uppercase;
}
.css-992tcj{
  padding-bottom: 20px;
  font-size: 16px;
  cursor: pointer;
}
.css-19ndbdu{
  margin-top: 12px;
}

.footer-container{
  background-color: #000000;
}
.footer-copy-right
{
  font-size: 16px;
  padding: 20px;
  color: #ffffff;
}
.menu-footer{
  margin-top: 20px;
  color: #ffffff;
  font-size: 16px;
  padding-right: 20px;
}
.footer-menu-container{
  margin-top: 20px;
}


.footer-links-list{
  list-style-type: none;
  margin-top: 10px;

}
.footer-links-list-item{
  margin-right: 15px;
  display: inline;
}
.sticky-content {
  position: fixed;
  top: 0;
  width: 100%;
}
.copyrigth-footer-container{
  margin-bottom: 130px;
}
.copyright-footer-container {
  text-align: center;
}
.logo-txt-footer{
  font-weight: bold;
  color:rgb(55, 180, 78);
  cursor: pointer;
}
.left-plc-footer-cont .css-6qjm3j {
  padding: 4px;
}
.left-plc-footer-cont .css-1rum9al {
  background: rgb(55 180 78 / 0.2);
}
.bottom-right-icon-left {
  transform: translate(-20%, -20%);
}
.bottom-right-icon-right {
  right: 60px;
  transform: translate(-20%, -20%);
}

.left-plc-footer-cont .css-6qjm3j {
  object-fit: contain;
}
.footer-list >li> a:hover
{
  color: #37b44e;
}
.footer-list >li> a
{
  color: #383838;
}
