.add-album-container{
   margin:0px auto;
}
.create-stroy-heading{
  margin-top: 20px;
}
.create-your-album-btn{
 float: right;
}
.image-gallery-thumbnail-inner> img{
min-height: 100px;
}
.single-album-image-banner>img{
  min-height: 180px;
}
.single-album-image{
  object-fit: cover;
}
