
.fav-txt{
  color: #fd5a5a;
}
.visted-txt{
 color: #37b44e;
}
.visit-right-arrow{
  margin-left:28%;
}
.home-visted-place-container{
  background-color: #f8f8f8;
}
