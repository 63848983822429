.travel-plan-container{
  background-color: #fbfbfb;
}
.travel-places-left-sec{
  display: flow-root;
  width: 50%;
  float: left;
}
.container-travelplan{
  background: rgb(255 255 255 / 0.6);
}
.travel-places-right-sec{
  width: 50%;
  float: right;
  display: flow-root;
}
.arrow-img-travel-place{
  margin-left: 100px;
}
.container-travel-places-inner{
padding-right: 0px !important;
padding-bottom: 0px;
}

@media screen and (max-width: 990px) {
  .travel-place-heading
  {
   margin-top:20%
  }
}

@media screen and (max-width: 900px) {
  .travel-place-heading
  {
   margin-top:12%
  }
}
@media screen and (max-width: 900px) {
  .travel-place-heading
  {
   margin-top:5%
  }
}
