/*--------------------navbar-------------------------------*/
.nav-container
{
    /* max-width:1366px; */
    width: 100%;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1) !important;
    background-color: #ffffff;
    display:block !important;
    box-shadow: 0px 0px 0px;
}
/* .half-container{
     height: 69px;
} */
.element-container{
    text-align: right;
    vertical-align: middle;
}
.css-lqt4hu{
    padding: 4px 30px;
}
.logo{
    width: 153px;
    height: 50px;
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.43;
    letter-spacing: normal;
    text-align: left;
    color: #37b44e;
    padding-top: 20px;
    cursor: pointer;

}
.login-btn{
    width: 130px;
    height: 44px !important;
    border-radius: 2px !important;
    border: solid 1px #cccccc !important;
    background-color: #ffffff !important;
     margin: 10px 20px 10px 0px;
     font-weight: 600 !important;
     font-size: 16px !important;

}
.login-btn:hover{
  color: #000 !important;
}
.login-btn:focus{
  color: #000 !important;
}
.header-icon-active{
  fill:#37b44e !important
}

.join-btn:hover{
  border: none !important;
}
.join-btn{
    width: 130px;
    height: 44px !important;
    border-radius: 2px !important;
    background-color: #37b44e !important;
    color: #ffffff !important;
    margin: 10px 20px 10px 0px;
    font-weight: 600 !important;
    font-size: 16px !important;

}
.login-text{
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-right: 10px;
}
.fb-logo{
    width: 42px;
    height: 42px;
    margin: 10px;
    display: inline-block;


}
.google-logo{
    width: 42px;
    height: 42px;
    margin: 10px;
    display: inline-block;
}
.header-btns{
    float: left;

}
.half-container.element-container{
    float: right;
    display: flow-root;
}
.social-logins{
    float: left;
    border-left: 1px solid #eeeeee;

}
.social-logins-google{
  float: left;
  padding: 10px;

}
.right-element-container{
    display: flow-root;
}
.inner-container{
    padding: 0px 24px;
    display: flow-root;
}
.login-btn-header{
  padding: 13px;
}
.logo-container{
    float: left;
    /* width: 20%; */
}
.element-container{
    float: left;
    /* width: 80%; */
}
.common-attr-header{
    float: left;
    margin: 16px 0px;
}
.explore-Map-header{

    font-size: 16px;
    font-weight: 500;
    margin-left: 5px;
    color: #000000 !important;
    padding-right: 24px;
}
.user-acc-dropdown > span{
  margin-left: 3px;
}
.pak-map-icn{
    margin:11px 0px 13px 16px
}
.header-user-name{
    text-align: left;
    font-size: 16px;
    color: #37b44e;
    margin-top: 10px;
}
.draft-txt{
  font-size: 18px;
  color: #000000;
  margin-top: 10px;
  font-weight: 500;
  padding-left: 20px;
}
.user-acc-dropdown{
    cursor: pointer;
    color: #757576 !important;
    text-align: left;
}

.custom-fb-class {
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: calc(.27548vw + 12.71074px);
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .3s,border-color .3s;
  background-color: #4c69ba;
  border: calc(.06887vw + .67769px) solid #4c69ba;
  width: 42px !important;
  height: 42px !important;
  border-radius:25px !important;
  margin: 10px;

}
.google-social-btn-header
{
  width: 42px !important;
  height: 42px !important;
  border-radius:25px !important;
}
.google-social-btn-header >div{
      margin: auto !important;
      padding-left: 10px;
      background-color: transparent !important;
}
.google-social-btn-header >span{
  padding: 0px !important;
}
.google-social-btn-header:focus
{
  border:none !important;
  font-weight: unset !important;
}
.custom-fb-class >i
{
  margin: auto;

}
  .ant-dropdown-menu-item > a:hover, .ant-dropdown-menu-submenu-title > a:hover{
   color:#37b44e !important ;
  }
.header-navigate-btn{
  border: none !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  margin-right: 8px;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
/* .header-btn-active{
  color: #37b44e !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  margin-right: 8px;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
} */
.header-navigate-btn>span{
  margin-left: 4px !important
}
/* .st0{fill:#37B44E;stroke:#37B44E;stroke-width:0.5;} */

.header-navigate-btn-pak-map{
  border: none !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  margin-right: 8px;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;

}
.header-navigate-btn-pak-map:hover{
   color: #37B44E !important;
}
.header-navigate-btn .anticon svg:hover{
  fill: #37B44E !important;
}
/* [ant-click-animating-without-extra-node='true']::after{
  box-shadow: 0 0 0 0 #1890ff !important;
} */
.acc-acount-drp-dwn-menu>li{
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #000 !important;
  margin: 10px 0px;
  text-transform: capitalize;

}
.acc-acount-drp-dwn-menu >li>a{
  color: #000 !important;
margin: 0px !important;
}
.acc-acount-drp-dwn-menu{
  width: 245px !important;
    top: -3px !important;
    right: -22px !important;
}
#icon_map_active > svg{
  fill: #37b44e !important;
}
