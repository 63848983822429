.admin-login-container{
  max-width: 520px;
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);

}
.admin-login-form{
  padding-left: 43px;
  padding-bottom: 20px;
}
.btn-admin-login{
  width: 100%;
  margin-top: 10px;
}
.agency-modal{
  padding: 0 32px 32px 32px;
}
.agency-headings{
  padding: 32px 32px 24px 0;
}
