@font-face {
  font-family: 'Roboto', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

}
body {
  font-family: 'Roboto', sans-serif !important;
  background-color: #fbfbfb;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1{
  display: block;
  font-size: 2em !important;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold !important;
}
h2{
  display: block;
  font-size: 1.5em !important;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold !important;
}
h3{
  display: block;
    font-size: 1.17em !important;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold !important;
}
h4{
  display: block;
  /* margin-block-start: 1.33em !important; */
  /* margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px; */
  font-weight: bold !important;
}
h5{
  display: block;
    font-size: 0.83em !important;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold !important;
}
h6{
  display: block;
  font-size: 0.67em !important;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold !important;
}
h1,h2,h3,h4,h5,h6{
  color: #000 !important;
}
@media print {
  html, body {
     display: none;  /* hide whole page */
  }
}
@media screen and (min-width: 1366px) {
  /* Styles go here */
  .inner-container-banner{
    max-width: 1366px;
    margin:0px auto !important
  }
  .inner-container-banner-stories{
    max-width: 1366px;
    margin:0px auto !important
  }
  .add-album-container{
    max-width: 1366px;
    margin:0px auto !important;
    width:70%;
  }
  .place-component-inner-container{
    max-width: 1366px;
    margin:0px auto !important
  }
  .members-inner-container{
    max-width: 1366px;
    margin:0px auto !important
  }
  .terms-privacy-inner-container{
    max-width: 1366px;
    margin:0px auto !important
  }
  .single-question-inner-container{
    max-width: 1366px;
    margin:0px auto !important
  }
  .single-story-inner-container{
    max-width: 1366px;
    margin:0px auto !important
  }
  .pak-map-inner-banner{
    max-width: 1366px;
    margin:0px auto !important
  }
  .upper-footer-container{
    max-width: 1366px;
    margin:0px auto !important
  }
  .inner-container{
    max-width: 1366px;
    margin:0px auto !important
  }
  .container-upper-footer
  {
   background-size: contain !important;
  }
}
