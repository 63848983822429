
.error-img{
  margin: auto;
  width: 50%;;
}
.error-heading-container{
  width: 100%;
  display: flow-root;
  margin-top: 20px;
}

.error-heading-txt{
  font-size: 150px;
  font-weight: 500;
  text-align: center;
  color: rgb(254, 90, 91);
  line-height: 1;
}
.erro-upper-txt{
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  color: rgb(55, 55, 55);
}
.not-fond-btn{
  text-align: center;
}
.not-fond-btn{
  border-radius: 20px;
  margin-top: 16px;
  margin-bottom: 8px;
}
