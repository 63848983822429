
.user-dashboard-inner-container{
  padding: 30px 30px 0px 30px;
}
.avatar-container-dashboard{
  float: left;
  width: 23%;
}
.map-container-dashboard{
  float: left;
  width: 77%;
  padding-left: 20px;
}
.user-card{
  z-index: 1;
  top: 100px;
  left: 10px;
  position: fixed;
}
.verification-card{
  z-index: 1;
  top: 527px;
  left: 10px;
  position: fixed;
}
.news-feed-container{
  float: left;
  width: 100%;
}
.right-side-bar-container{
  float: left;
  width: 20%;
}

/* --------------------------------------------------About----------------------------------------- */

.user-about-first-container{
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  margin: 10px 0px;
  background: #fff !important;
}

.user-about-about-me-container{
  padding: 16px;
}
.user-about-inner-container{
  border-bottom: 1px solid #eeeeee;
}
.user-about-profession-container{
  padding: 16px;
}
.user-about-personal-container{
  padding: 16px;
  display: flow-root;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  margin: 16px 0 16px 0;
  background: #fff !important;
}
.user-about-profession-txt{
  margin-top: 10px;
}
.user-about-personal-left-container{
  float: left;
  width: 50%;
}
/* --------------------------------------------------About----------------------------------------- */
.user-place-btns{
  width: 42%;
  margin-right: 50px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  border: none !important;
  background-color:rgba(55, 180, 78,0.1)
}

.user-place-img-container{
  bottom: -23px !important;
}
.user-place-carusal-container{
  margin-top: 20px !important;
}
.user-place-photo-desc{
  padding: 10px;
}
.user-place-photo-container{
 margin-left: 10px;
  background: linear-gradient( to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.7) );
  width: 94%;
}
.badge-container-user>.dot-badge-user-profile>.ant-badge-status-text{
  font-size: 16px!important;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left;
  color: #000!important;
}
.dot-badge-user-profile>.ant-badge-status-default{
  width: 24px !important;
 height: 24px !important;
 margin-bottom: 5px;
 margin-top: 5px;
}
.user-card-static-data{
  font-size: 16px !important;
  font-weight: 400;
  color: #000!important;
}
.user-card-dynamic-data{
  font-size: 16px !important;
  font-weight: 400;
}
.user-data-icons{
  margin-left: 0;
    padding-right: 0;
    color: #37b44e;
    font-size: 18px;
}
.user-data-icon-wrapper{
  background-color: rgb(55 180 78 / 0.2);
  width: 32px;
  height: 32px;
  float: left;
  margin-right: 5px;
  border-radius: 50%;
  text-align: center;
  padding: 1.5px 7px;
}
.user-dynamic-data-wrapper{
  padding-top: 3px;
}
.user-data-link{
  padding-top: 3px;
  font-size: 16px;
}
.user-data-link:hover{
  color: #29a829 !important;
}

.verified-email-txt{
  font-size: 16px;
}
.verification-card-caontainer{
  margin-bottom: 10px;
}
.user-data-icon-wrapper-about{
  margin-top: 6px;
    background-color: rgb(55 180 78 / 0.2);
    width: 32px;
    height: 32px;
    float: left;
    margin-right: 5px;
    border-radius: 50%;
    text-align: center;
    padding: 1.5px 7px;

}
.user-data-icon-wrapper-personal-info{
  width: 6%;
}
.friend-card-main-container{
  width: 25%;
  float: left;
  margin: 10px 10px 10px 0px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  padding: 20px;
  min-height: 219px;
}
.friend-card-user-txt{
  font-size: 16px !important;
}
.user-data-category-text{
  font-size: 18px;
    color: #000 !important;
    text-align: center;
    font-weight: 500;
}
.user-about-profession-txt .user-card-desc {
  color: #666666;
  font-size: 16px;
  font-weight: 400;
}
.user-card-desc {
color: #383838;
font-size: 18px;
font-weight: 400;
}
.user-dashboard-footer{
  width:100%;
  margin-top: 60px;
  float:left
}

.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #37b44e !important;
}
.ant-menu-horizontal > .ant-menu-item a {
  font-weight: 500 !important;
  color: #383838 !important;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #37b44e !important;
}

.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {

  border-bottom: 2px solid #37b44e !important;
}
.user-visted-place-container {
  background-color: #fff;
  width: 45%;
  margin-top: 32px;
}
.fav-place-container {
  background-color: #fff;
  width: 100%;
  margin-top: 32px;
}
.fav-place-container .no-data-container {
  width: 65%;
  text-align: center;
  float: right;
}
