.hero-container{
    width: 100%;
  background-color: #fafafa;
  height: auto;
  display: block;
  position: relative;
  overflow: hidden;
}
.hero__background:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0.2)),to(rgba(0,0,0,0.2))),-webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0.2)),to(transparent));
  background: linear-gradient(180deg,rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.2) 100%),linear-gradient(180deg,rgba(0,0,0,0.2) 0%,transparent 100%);
  z-index: 0;
}

.hero__background-img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0.5)),to(rgba(0,0,0,0.5))),-webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0.2)),to(transparent));
  background: linear-gradient(180deg,rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%),linear-gradient(180deg,rgba(0,0,0,0.2) 0%,transparent 100%);
  z-index: 0;
}
.hero__background-members{
  height: 500px;
  width: 120%;
  overflow: hidden;
  margin: auto;
}
.hero__background-members:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0.5)),to(rgba(0,0,0,0.5))),-webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0.2)),to(transparent));
  background: linear-gradient(180deg,rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%),linear-gradient(180deg,rgba(0,0,0,0.2) 0%,transparent 100%);
  z-index: 0;
}

.hero__background {
  height: 500px;
  overflow: hidden;
}
hero__background-img{
  height: 500px;
  overflow: hidden;
}
.hero-heading{
  font-size: 42px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 26px;
}
.banner-desc{
text-align: center;
color: white;
font-size: 22px;
margin-bottom: 30px;
}
.explore-btn:focus{
  border:2px solid #fff !important;
}
.explore-btn{
  background-color: transparent;
  color: white;
  font-size: 18px;
  border: 2px solid #fff;
  padding: 7px 20px;
}
.explore-btn .anticon {
  margin-left: 10px;
}
/* .explore-btn:hover{
  color:#000000 !important;
  background-color: #fff !important;
} */
.hero-join-btn .anticon{
  margin-left: 10px;
}
.hero-join-btn{
  margin-left: 32px;
  font-size: 18px;
  padding: 5px 20px;
  background-color: #37b44e;
  border: 2px solid #37b44e;
  color: #fff;
}
.explore-btn > span > svg{
  margin-top: -8px;
  margin-bottom:0px !important;
}

.video-overlay-txt{
  margin-bottom: 26px;
  text-align: center;
}
.banner-btn-container{
  text-align: center;
  color: #ffffff;
}
.video-overlay-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 874px;
}
.hero__background video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
}
.pakrism-txt{
  color: #37b44e;
}
.left-sec{
    float: left;
    width: 50%;
}
.right-sec{
    float: left;
    width: 50%;
}
.left-container{
    margin: 105px 0px 0px 30px;
}
.welcome-text{
    font-family: Roboto;
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.43;
    letter-spacing: normal;
    text-align: left;
    color: #37454d;
    line-height: 3em;
}
.pakistan-tourism{
    height: 55px;
    font-family: Roboto;
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.43;
    letter-spacing: normal;
    text-align: left;
    color: #37b44e;
}
.hero-content{
    font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.explore-links{
    margin-top: 40px;
    width: 286px;
    height: 44px;
    border-radius: 2px;
    background-color: #f2f2f2;

}
.explore-links-sec{
    margin-top: 20px;
    width: 286px;
    height: 44px;
    border-radius: 2px;
    background-color: #f2f2f2;
}
.dot-style{
   font-size: 150px;
   display: inline-block;
}
.common-attr{
    float: left;
    margin: 10px 0px 10px 0px;
}
.icons{
    margin-left: 20px;
}
.explore-Map{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    margin-top: 12px;
    margin-left: 45px;
}
.arrow-img{
position: absolute;
margin-left: 270px;
margin-top: -48px;
border-radius: 30px;

}
.hero-heading-travel-stories{
  text-align: center !important;
}
.pak-map{
    position: absolute;
    margin-left: -10px;
    margin-top: -4px;

}
.hero-main-img{
    margin-top: 82px;
}
.travel-plan-img{
    width: 500px;
    display: block;
    margin-left: auto;
    margin-right: auto;

}
.mobile-btns{
    margin-top: 60px;
    float: left;
}
.mob-apple{
    width: 200px;
    margin-right: 20px;
    margin-bottom: 72px;
}
.mob-ios{
    width: 200px;
}
.hero__background img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #232a34;
}
.usr-image-member{
  width: 250px !important;
  height: 250px !important;
  object-fit: cover;
}
@media screen and (max-width: 1242px) {
  .banner-avatar {
   margin-top: 10px !important ;
  }
}
@media screen and (max-width: 900px) {
  .video-overlay-container {
    width: 90% !important ;
  }
}

