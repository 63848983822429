.stories-main-container{
  display: flow-root;
  padding-top: 60px;
}
.stories-container-inner-left{
  width:75%;
  float: left;
}
.stories-container-inner-right{
  width:25%;
  float: left;
  padding:0px 10px;
  position: sticky;
  top: 16px;
  margin-bottom: 125px;
}
.inner-container-stories{
  display: flow-root;
}
.adverties-img{
  height: 350px;
  width: 100%;
}
.stories-advertise-container{
  margin-bottom: 30px;
}
.top-story-taller-heading{
  color: #000;
  text-transform: capitalize;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.7;
  margin-bottom: 10px;
}
.album-container-inner-right{
width: 25%;
    float: left;
    padding: 0px 10px;
    position: sticky;
    top: 16px;
    margin-bottom: 125px;
}
.travel-story-taller-heading{
  color: #000;
  text-transform: capitalize;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.7;
  margin-bottom: 10px;
}

.top-story-teller-list{
  list-style: none;
  width: 100%;
  padding: 16px ;
}

.inner-container-banner-stories{
  padding-left: 30px;
  padding-right: 30px;
  display: flow-root;
}

.stories-container{
  padding-right: 80px;
}

/* --------------------------------Single story---------------------------------------------------- */

.single-story-inner-container{
  padding: 30px;
  display: flow-root;

}
.single-story-add-friend-container{
  width: 10%;
  float: left;
  margin-top: 85px;
  position: sticky;
  top: 0;
  z-index: 1;

}
.single-story-data-container{
  width: calc(65% - 60px);
  margin: 0 30px;
  float: left;
}
.single-story-advertise-container{
  width: 25%;
  float: left;
}
.single-story-advertise-container{
  margin-top: 85px;
  position: sticky;
  top: 0;
  z-index: 1;
}

/* --------------------------------Single story---------------------------------------------------- */

/* --------------------------------My story---------------------------------------------------- */
.my-story-tab-list{
  border-bottom: none !important
}

.my-story-tab:focus{
  box-shadow: none !important;
}
.css-w6ysom[aria-selected=true]{
color: #37b44e !important;
}
.css-1dxxh68[aria-selected=true]{
  color: #37b44e !important;
  }
  .css-w6ysom:focus{
    box-shadow: none !important;
  }
/* --------------------------------My story---------------------------------------------------- */

/* --------------------------------Published story---------------------------------------------------- */
.published-card-container{
  border-radius: 10px;
  margin: 30px 0;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid rgba(33,43,54,.05);
  padding: 15px;
}
.published-card-inner-left-container{
  display: flow-root;
  padding-bottom: 30px;
}
.publish-card-image-banner{
  display: flow-root;
  float: left;
  width: 25%;
}
.published-card-data-container{
  display: flow-root;
  float: left;
  width: 75%;
  padding-left: 20px;
}
.published-card-story-title{
  font-size: 22px;
    font-weight: 500;
}
.published-card-title-container{
  width: 95%;
  float: left;
}
.published-card-menu-container{
  width: 5%;
  float: left;
}
.published-img-banner{
  border-radius: 10px;
}
/* --------------------------------Published story---------------------------------------------------- */

/* --------------------------------Draft story---------------------------------------------------- */

.draft-story-btn-container{
  text-align: right;
}

/* --------------------------------Draft story---------------------------------------------------- */
/* --------------------------------write story---------------------------------------------------- */

.write-story-container{
  width: 70%;
}
.write-story-form{
  margin-top:20px;
}
.write-story-editor{
  min-height: 150px;
  border: 1px solid #cccccc;
  border-radius: 3px;
}
.write-story-editor:hover{
  border-color: #999 !important;
}
.write-story-editor:focus{
  border:1px solid #d9d9d9 !important;
}
.medium-editor-placeholder:after{
font-style: normal !important;
color: rgba(0, 0, 0, 0.65) !important;
}
.story-title-input{
  border: none !important;
  font-size: 32px !important;
}
.quill-composer-main .ql-toolbar.ql-snow{
border: none !important;
text-align: center;
}
.quill-composer-main .ql-container.ql-snow{
  border: none !important;
}
.ql-editor{
   font-size: 18px;
  color: rgba(0, 0, 0, 0.65)
}
.select-places .ant-select-selector{
  border: none !important;
}
.story-title .ant-form-item-label .ant-form-item-required::before{
content: none !important;
}
.story-title .ant-form-item-label .ant-form-item-required::after{
  content: none !important;
  }
  .story-title .ant-form-item-label .ant-form-item-required{
    font-size: 16px;
    margin-top: 16px;
    border-right: 1px solid #eee;
    font-weight: normal !important;
    padding: 15px;
  }
  .composer-title .ant-form-item-label .ant-form-item-required::before{
    content: none !important;
  }
  .composer-title .ant-form-item-label .ant-form-item-required::after{
    content: none !important;
  }
  .composer-title .ant-form-item-label .ant-form-item-required{
    font-size: 16px;
    margin-top: 48px;
    border-right: 1px solid #eee;

    font-weight: normal !important;
    padding: 15px;
  }
  .place-title .ant-form-item-label label::after{
    content: none !important;
  }
  .place-title .ant-form-item-label label{
    font-size: 16px;
    margin-top: 3px;
    border-right: 1px solid #eee;
    font-weight: normal !important;
    padding: 15px;
  }
  .story-input-other-title{
    border: none !important;
  }
  .write-sotry-desc-txt{
    font-size: 12px !important;
    color: rgb(212, 212, 212) !important;
    padding-left: 10px;
  }
  .photo-title .ant-form-item-label label::after{
    content: none !important;
  }
  .photo-title .ant-form-item-label label{
    font-size: 16px;
    margin-top: 3px;
    border-right: 1px solid #eee;
    font-weight: normal !important;
    padding: 15px;
  }
  .write-story-drager{
    margin-left:10px
  }
  .photo-title .ant-form-item-control-input-content{
    background-color: #e7fce5 !important;
    border: 1px dashed rgb(212, 212, 212)  !important;
  }
  .write-story-drager{
    border: none !important ;
    margin-right: 5px !important;
  }
  .write-stroy-btns .ant-form-item-control{
margin-left:148px !important;
  }

  /* .ql-editor::-webkit-scrollbar {

    width: 16px;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
  } */

/* --------------------------------write story---------------------------------------------------- */
.post-story-comment-container{
  border-bottom: 1px solid #eee;
}
.show-comments-single-story-container{
  padding: 10px;
  margin-top: 10px;
}
.show-comment-left-container{
  float: left;
  width: 10%;
  cursor: pointer;
}

.single-story-card-inner-left-container{
  cursor: pointer;
}
.sotry-like-footer{
   border-bottom: 1px solid #eee;
  margin-bottom: 16px !important;
}
.place-photo-modal .ant-modal-close-x {
  color: #fff !important;
  font-size: 32px !important;
  line-height: 52px !important;
}
.place-photo-modal .ant-modal-title {
  color: #fff !important;
}
.place-photo-modal .ant-modal-close {
  position: sticky;
  left: 100%;
}
.place-photo-modal .ant-modal-header {
  padding: 16px 24px;
  color: #fff;
  background: #37b44e;
  border-bottom: 1px solid #37b44e;
  border-radius: 2px 2px 0 0;
  position: sticky;
  top: 0;
  margin-top: -55px;
}
