.members-left-container{
    float: left;
    width: 75%;
}
.members-ad-container{
    width: 25%;
    float: left;
    padding: 0px 10px;
    position: sticky;
    top: 16px;
    margin-bottom: 125px;
}
.members-inner-container{
    display: flow-root !important;
    margin-top:60px !important;
    padding-left: 30px;
    padding-right: 30px;
}
.members-friend-card-main-container{
    width: 32%;
    float: left;
    margin: 0px 10px 10px 0px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    padding: 20px;
    min-height: 219px;
}
.top-travler-inner-container{
    display: flow-root;
    cursor: pointer;
   margin-bottom:16px;
}
.top-users-headings{
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}
.top-user-avatar-container{
    float: left;
     margin-bottom: 16px;
     width: 100%;
     text-align: center;
}
.top-user-txt-container{
    float: left;
    text-align: center;
    width: 100%;
}
.un-friend-btn{
    font-size: 18px;
    padding: 5px 20px;
    background-color: #fff;
    border: 1px solid #37b44e;
    color: #37b44e;
}
.friends-members-container{
  width: 100%;
  float: left;
}
.friend-requests-members-container{
  width: 100%;
  float: left;
}
.received-request-container{
  width: 100%;
  float: left;
}
.all-members-container{
  display: flow-root;
  margin-top: 16px;
}
.all-members-heading{
  font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
}
.tripmate-btn-container{
  text-align: center;
}
.friend-requests-inner-heading-container{
  font-size: 18px;
    font-weight: bold;
    margin-bottom: 24px;
}
