
.mobile-container
{
background-image: url('../../Assets/flag_bg-01.svg');
object-fit: contain;
background-color: #37b44e;
background-repeat: no-repeat;
position: relative;
}
.mobile-img-left{
  height: 495px;
}
.mobile-headings{
  font-size: 42px !important;
  font-weight: bold;
  color: #ffffff;
}
.apple-store-btn{
  margin-right: 10px;
}
.mobile-desc{
  font-size: 25px;
  color: #ffffff;
  margin-top: 30px;
}
.mobile-desc-one{
  font-size: 21px;
  color: #ffffff;
  margin-top: 10px;
}
.app-store-btn{
  width: 257.2px;
  height: 73.1px;
  object-fit: contain;
  margin-left: 40px;
}
.apple-btn-container{
  float: left;
  width: 50%;
  margin-top: 30px;
  display: flex;
}
.mobile-img-container{
z-index: 1;
}
.mobile-img-right{
  height: 335px;
}
.qa-album-right{
  padding: 35px;
}
.mobile-img-container-2nd{
z-index: 0;
margin-left: -50px;
}
.mobile-headings {
  color: #fff !important;
}
.mobile-images{
  display: flex;
  align-items: center;
  margin-top: -60px;
  margin-bottom: -60px;
}
@media screen and (max-width: 1500px) {
  /* Styles go here */
  .mobile-container{
    background-size: 35%;
    background-position: left center;

  }
}
@media screen and (max-width: 1280px) {
  /* Styles go here */
  .mobile-container{
    background-size: auto;

  }
}
@media screen and (max-width: 1024px) {
  /* Styles go here */
  .mobile-container{
    background-size: auto;
    background-position: center;

  }
}
@media screen and (max-width: 944px) {
  /* Styles go here */
  .mobile-img-left{
   height: 595px !important;

  }
}
