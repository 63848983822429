.ant-input:hover{
  border-color: #999 !important;
  border-radius: 3px;
}
.ant-input:focus, .ant-input-focused{
  border-color: #999 !important;
  box-shadow: none !important;
}
.table-img{
  cursor: pointer;
}
.ant-upload-list-picture .ant-upload-list-item-error, .ant-upload-list-picture-card .ant-upload-list-item-error{
  border-color: #eee !important;
}
.ant-upload-list-picture-card-container .ant-tooltip
{
  display: none !important;
}
