#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}
.trigger{
  padding-left: 20px;
}

.admin-acc{
  float: right;
  padding-right: 23px;
  cursor: pointer;
}
.ant-layout-sider{
  background: #fff !important;
}
.logo{
  width: auto !important;
  font-size: 24px !important;
}
.avatar-user-header > img
{
  width: 86% !important;
  height: initial !important;
}

