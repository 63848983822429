.filter-container{
  float: left;
  width: 75%;
  min-height: 750px;
}
.pak-map-inner-container{
  display: flow-root;

}
.all-cat-txt{
  padding-left: 30px;
  font-size: 18px;
  color: #000 !important;
}
.filters{
  display: flow-root;
  padding: 20px 30px;
}
.filter-place-container{
 max-height: 87vh;
 overflow-y: auto;
 overflow-x: hidden;
}
.fliter-list{
  list-style: none;
}
.filter-item{
  float: left;
}
.fliter-list .filter-item:first-child{
  font-size: 18px;
  font-weight: 500;
  padding-right: 15px;
  padding-top: 5px;
}
.filter-item{
  padding-right: 10px;
}
.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);

}
.side-bar-container{
  display: flow-root;
  margin-top: 10px;
  width: 25%;
 padding-left: 10px;
 padding-right: 10px;
 max-height: 750px;

}
.react-multi-carousel-item {

  padding:0px 15px
}
.react-multi-carousel-item *{
text-align: center;
}
.category-avatar >img{
width: 100% !important;
height: 100% !important;
}
.category-text{
  font-size: 18px;
  color: #000 !important;
  text-align: center;
}
.category-container-pak-map{
  cursor: pointer;
  margin-top: 20px;
}
.tooltip-container{
  width: 300px;
    background-color: azure;
}
.image-container{
  width: 30%;
  float: left;
}
.data-container{
  width: 70%;
  float: left;
  height: 80px;
  background-color: white;
  color: #000;
  padding: 0px 10px;

}
.tooltip-place-name{
  font-size: 16px;
  font-weight: 500;
}
.tooltip-category-name{
  font-size: 12px;
  color: #333333;
}
.decs{
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.ant-tooltip-inner{
background-color: transparent !important;
box-shadow: none !important;
-webkit-box-shadow:none !important;
display: flow-root;
cursor: pointer;
}
.tooltip-img{
  height: 80px;
  width: 100%;
}
.tooltip-img-broken{
  height: 100px;
}
.footer-container-1{
  border-top: #f9f9f9;
  margin-top: 30px;
}
.tooltip-map{
  cursor: pointer !important;
}
.react-multiple-carousel__arrow--right {
  right: calc(0% + 0px) !important;
  top: 20%;
}
.react-multiple-carousel__arrow {
  background: rgb(55 180 78 / 0.6) !important;
}
.react-multiple-carousel__arrow:hover {
  background: #37b44e !important;
}
.react-multiple-carousel__arrow--left {
  top: 18%;
}
.tooltip .tooltiptext {
  background-color: #37b44e;
}
.tooltip .tooltiptext:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 13%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #37b44e transparent transparent transparent;
}
.filter-item.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
  width:150px !important;
}
.filter-item-last.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
  width: 150px !important;
}
.select-places-pak-map{
  width: 93%;
    /* border: 1px solid #eee; */
    border-radius: 3px;
    margin: 10px;
}
